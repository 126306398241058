import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { IconClose } from '@loadsmart/icons'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Button } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'

import { Icon } from 'core/ui/components/icon/Icon'
import { SurveyBannerController } from 'core/ui/components/survey-banner/SurveyBannerController'
import { Container } from 'core/ui/Container'

import { screen } from 'legacy/styles/screen'

import { ConfigProvider } from 'config/ui/ConfigContext'

import { useNavigationContext } from '../../contexts/NavigationContext'
import { Logo } from './Logo'
import { PrivateMenu } from './PrivateMenu'
import { PublicMenu } from './PublicMenu'

const Header = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  min-height: var(--navbar-height);
  background-color: ${toCSSValue('color-primary-100')};
  backface-visibility: hidden;
  transform: translateZ(0);
  z-index: 150;
`

const Content = styled(Container)`
  min-height: var(--navbar-height);
  padding: 0 ${getToken('space-m')};
  display: flex;
  align-items: center;
`

interface MenuContentProps {
  isOpen?: boolean
}

const MenuContent = styled.nav<MenuContentProps>`
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  background-color: ${toCSSValue('color-primary-100')};
  transform: translate3d(100%, 0, 0);
  backface-visibility: hidden;
  transition: transform 300ms ease-in-out;
  overflow: hidden;

  ${screen.lg} {
    width: auto;
    height: 50px;
    overflow: initial;
    position: initial;
    transform: none;
    transition: none;

    display: flex;
    align-items: center;
  }

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: translate3d(-100%, 0, 0);
          position: fixed;
        `
      : ''}
`

const Spacer = styled.div`
  flex-grow: 1;
`

const ButtonMenu = styled(Button)`
  ${screen.lg} {
    display: none;
  }
`

const ButtonClose = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  color: ${getToken('color-neutral-light')};
  display: block;
  position: absolute;
  top: ${getToken('space-m')};
  right: ${getToken('space-m')};

  ${screen.lg} {
    display: none;
  }
`

const LogoWrapper = styled(Logo)`
  margin-top: ${getToken('space-xs')};
  margin-left: ${getToken('space-m')};

  ${screen.lg} {
    display: none;
  }
`

const pagesWithoutNavigation = [
  'auth',
  'login',
  'reset-password',
  'set-new-password',
  'signup',
  'verify',
  'all-in-one',
]

function shouldHideNavigation(path: string, logged: boolean) {
  if (path === '/') return !logged
  return pagesWithoutNavigation.some(curr => path.includes(curr))
}

export type NavigationProps = {
  logged: boolean
}

export function Navigation({ logged }: NavigationProps) {
  const { pathname } = useLocation()
  const { opened, toggle, close } = useNavigationContext()
  const hideNavigation = shouldHideNavigation(pathname, logged)

  if (hideNavigation) return null

  return (
    <Header>
      <ConfigProvider>
        <SurveyBannerController flagKey="kamionIntegrationSurvey" />
        <Content>
          <Logo isVisible={!opened} />
          <Spacer />
          <ButtonMenu
            variant="primary"
            type="button"
            onClick={toggle}
            leading={<Icon name="burger-menu" />}
          >
            MENU
          </ButtonMenu>
          <MenuContent isOpen={opened}>
            <LogoWrapper />
            {logged ? <PrivateMenu /> : <PublicMenu />}
            <ButtonClose type="button" onClick={close}>
              <IconClose width={30} height={30} />
            </ButtonClose>
          </MenuContent>
        </Content>
      </ConfigProvider>
    </Header>
  )
}
