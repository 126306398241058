import user from 'legacy/utils/user'

import { Apps } from './Apps'
import { Contracts } from './Contracts'
import { Manage } from './Manage'
import { MenuList } from './Navigation.styles'
import { NavigationItem } from './NavigationItem'
import { Phone } from './Phone'
import { Profile } from './Profile'

export function PrivateMenu() {
  const isCarrier = user.get('entityType') === 'carrier'
  const notDriver = user.role !== 'driver'

  return (
    <MenuList>
      {isCarrier ? <CarrierMenu /> : <BrokerMenu />}
      <Profile />
      {notDriver && <Apps />}
    </MenuList>
  )
}

function BrokerMenu() {
  return (
    <>
      <NavigationItem title="Book" url="/loads" />
      <NavigationItem title="Contracts" url="/rfp" />
      <NavigationItem title="My Loads" url="/shipments/shipper-loads" />
      <NavigationItem title="Manage" url="/fleet" />
    </>
  )
}

function CarrierMenu() {
  return (
    <>
      <Phone />
      <NavigationItem title="Book" url="/loads" />
      <Contracts />
      <NavigationItem title="My Loads" url="/shipments/loadsmart-loads" />
      <Manage />
    </>
  )
}
